<template>
  <div class="help-topic-container" v-if="childDataLoaded">
    <h1>{{ page.name }}</h1>
    <div
      class="page"
      v-for="(pageItem, itemIndex) in pageContent"
      :key="itemIndex"
    >
      <h2 v-if="isQuestion(pageItem.type)">{{ pageItem.fields.question }}</h2>
      <template v-else-if="isAnswerSection(pageItem.type)">
        <h3 v-if="pageItem.fields.section_title">
          {{ pageItem.fields.section_title }}
        </h3>
        <div class="wysiwyg" v-html="pageItem.fields.answer_part"></div>
      </template>
      <div
        v-else-if="isImageSection(pageItem.type)"
        :class="imageSetClasses(pageItem)"
      >
        <button
          v-for="(item, imageIndex) in pageItem.fields.images"
          @click="showEnlarged(item, itemIndex, imageIndex)"
          :key="`${itemIndex}-${imageIndex}`"
          :id="imageId(itemIndex, imageIndex)"
          class="thumb-button"
        >
          <img :src="item.image" :alt="item.description" />
        </button>
      </div>
    </div>
    <div
      v-if="shouldShowEnlargedImage"
      @click="clearEnlargedImage"
      class="enlarged-image"
    >
      <img
        :src="enlargedImageData.image"
        :alt="enlargedImageData.description"
      />
    </div>
    <p class="contact-container">
      <span class="advisory">{{ $t('helpTopicContactUsText') }}</span>
      <btn
        custom-class="button-link"
        customClass="contact action-link"
        :text="$t('helpTopicContactUsLinkText')"
        @btn-click="openWidget"
      />
    </p>
    <div v-if="page.name" class="action-container">
      <btn
        custom-class="button-link"
        text="Back to Help Center"
        :href="{ name: 'help-center' }"
      >
      </btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn.vue';
import { butter } from '@/buttercms';

export default {
  name: 'Page',
  components: {
    Btn,
  },
  data() {
    return {
      title: 'Page',
      slug: this.$route.params.slug,
      page: {
        slug: '',
        fields: {},
      },
      enlargedImageData: null,
      childDataLoaded: false,
    };
  },
  computed: {
    pageContent() {
      return this.page.fields.question_and_answer_set || [];
    },
    shouldShowEnlargedImage() {
      return !!this.enlargedImageData;
    },
  },
  methods: {
    fetchPage() {
      const locale = this.$route.params.locale;
      butter.page
        .retrieve('help_page', this.slug, { locale: locale })
        .then(res => {
          this.page = res.data.data;
          this.childDataLoaded = true;
        })
        .catch(e => {
          console.warn(e);
          this.$router.push({ name: 'help-center' });
        });
    },
    isQuestion(type) {
      return type === 'q_and_a_question_section';
    },
    isAnswerSection(type) {
      return type === 'q_and_a_answer_section';
    },
    isImageSection(type) {
      return type === 'q_and_a_image_trio';
    },
    showEnlarged(imageData, itemIndex, imageIndex) {
      this.enlargedImageData = {
        ...imageData,
        itemIndex: itemIndex,
        imageIndex: imageIndex,
      };
    },
    clearEnlargedImage() {
      const targetId = this.imageId(
        this.enlargedImageData.itemIndex,
        this.enlargedImageData.imageIndex
      );
      document.querySelector(`#${targetId}`).focus();
      this.enlargedImageData = null;
    },
    imageId(itemIndex, imageIndex) {
      return `image-${itemIndex}-${imageIndex}`;
    },
    imageSetClasses(pageItem) {
      return ['image-set', { single: pageItem.fields.images.length === 1 }];
    },
    openWidget() {
      this.$zendesk.open();
    },
  },
  created() {
    this.fetchPage();
  },
};
</script>

<style scoped lang="scss">
.help-topic-container {
  margin-bottom: 4rem;

  ::v-deep ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
    list-style: none inside;
    counter-reset: ol-counter;

    > li {
      counter-increment: ol-counter;

      &::before {
        content: counter(ol-counter) '. ';
      }
    }
  }

  ::v-deep h2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  ::v-deep h3 {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .image-set {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 2rem;

    .thumb-button {
      padding: 0;
      max-width: 33%;
      background: none;
      border: none;
      cursor: zoom-in;
    }

    img {
      max-width: 100%;
      max-height: 24rem;
    }

    &.single {
      .thumb-button {
        max-width: 100%;
      }

      @include bp-mediumsmall {
        justify-content: flex-start;

        .thumb-button {
          max-width: 50%;
        }
      }
    }
  }

  ::v-deep iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .enlarged-image {
    @include flex-center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: zoom-out;

    > img {
      max-width: 90vw;
      max-height: 90vh;
    }
  }
}

.contact-container,
.action-container {
  @include bp-xsmall {
    margin: 2rem 0 0;
  }
  @include bp-small {
    margin: 3rem 0 0;
  }
  @include bp-medium {
    margin: 4rem 0 0;
  }
  @include bp-large {
    margin: 5rem 0 0;
  }
}

.contact {
  font-weight: 700;
  background: transparent;
}
</style>
